<template>    
    <div class="layouts--footer">
        <div class="footer-content">
            <div class="row">
                <div id="top" v-on:click="goToPage('/')">
                    <img title="Главная страница" class="logo" src="@/assets/images/logo_white.png">
                </div>
                <div class="desc">
                    ЗАБОР-РЕГИОН - это строительная организация, выполняющая один или несколько видов строительных работ,
                    способная под свою ответственность выполнить строительные работы по заданию заказчика за определенную плату
                    с использованием собственных материалов или материалов заказчика. <br>Официальный сайт организации "ЗАБОР-РЕГИОН".
                </div>
            </div>
            <div class="row">
                <div>
                    <div class="address">Липецк. Липецкая область 2013-2024г.</div>
                    <div class="contact">Вы можете связаться с нами и оформить заказ на нашу почту: zabor-region@mail.ru</div>
                </div>
            </div>
            <div class="row">
                <div class="contacts">
                    <div class="icon">
                        <a href="tel:+79205352806"><img src="@/assets/phone.svg"></a>
                    </div>
                    <div class="info">
                        <div class="call-number"><a href="tel:+79205352806">+7 920 535-28-06</a></div>
                        <div>Без выходных с 9:00 до 21:00</div>
                    </div>
                </div>

                <div class="location">
                    <location></location>
                </div>

                <order-button></order-button>
            </div>
        </div>
    </div>
</template>
<script>
    import Location from '@/components/Location';
    import OrderButton from '@/components/OrderButton';

    export default {
        components: {
            Location, OrderButton
        },
        data() {
            return {};
        },
        computed: {

        },
        methods: {
            goToPage(page) {
                this.$router.push(page);
                window.scrollTo(0,0);
            }
        },
    };
</script>
<style scoped>
    .layouts--footer {
        width: 100%;
        background-color: #212121;
        flex: 0 0 auto;
        padding: 40px 0;
        box-sizing: border-box;
    }
    .footer-content {
        max-width: 1100px;
        margin: auto;
    }
    .row:nth-of-type(1) {
        width: 85%;
        display: flex;
        margin: 20px auto;
    }
    .row:nth-of-type(1) .logo {
        width: 200px;
        margin-right: 20px;
        height: 100%;
        cursor: pointer;
    }
    .row:nth-of-type(1) .desc {
        color: #ffffff;
        font-size: 12px;
        align-self: center;
		padding-top: 10px;
    }

    .row:nth-of-type(2) {
        border-bottom: 1px solid #3C3C3C;
    }
    .row:nth-of-type(2) > div {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        width: 85%;
        margin: 10px auto;
        font-size: 13px;
    }
    .row:nth-of-type(2) .address {
        display: inline-block;
    }
    .row:nth-of-type(2) .contact {
        text-align: right;
        display: inline-block;
    }

    .row:nth-of-type(3) {
        display: flex;
        margin-bottom: 25px;
        margin-top:7px;
    }
    .row:nth-of-type(3) > div {
        display: flex;
        vertical-align: middle;
        margin: auto;
        position: relative;
        top: 6px;
    }
    .contacts {
        display: flex;
        align-items: center;
    }
    .icon {
        float: left;
        margin-right: 5px;
    }
    .icon > a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .icon img {
        width: 40px;
        margin-right: 5px;
    }
    .info {
        text-align: left;
        white-space: nowrap;
        display: inline-block;
        font-weight: bold;
    }
    .info > div:nth-of-type(2) {
        color: grey;
        font-size: 11px;
        margin-top: 3px;
        font-weight: normal;
    }
    .call-number > a {
        text-decoration: none;
        color: black;
    }
    .footer-content .info > div {
        color: #ffffff;
    }
    .footer-content .call-number> a {
        color: #ffffff;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    @media screen and (max-width: 690px) {
        div.info {
            font-size: 13px;
        }
        .call-number {
            font-size: 13px;
        }
        .mess-text {
            font-size: 9px;
        }
        div.info > div:nth-of-type(3) {
            font-size: 9px;
        }
        div.icon img {
            width: 38px;
        }
    }
    @media screen and (max-width: 520px) {

    }
    @media screen and (max-width: 450px) {
        .messenger__contact {
            display: none;
        }
        .footer-content .info > div {
            display: none;
        }
        .footer-content .icon img {
            display: none;
        }
    }
    @media screen and (max-width: 690px) {
        .row:nth-of-type(1) .desc {
            font-size: 11px;
        }
        .row:nth-of-type(2) .contact {
            font-size: 11px;
        }
        .row:nth-of-type(2) .address {
            font-size: 11px;
        }
    }
    @media screen and (max-width: 570px) {
        div.location {
            display: none;
        }
    }
    @media screen and (max-width: 520px) {
        div .row {
            justify-content: center;
        }
        .row:nth-of-type(1) .logo {
            width: 170px;
            height: 100%;
            margin-right: 0px;
         }
        .row:nth-of-type(1) .desc {
            display: none;
        }
        .row:nth-of-type(2) .contact {
            font-size: 11px;
            text-align: center;
        }
        .row:nth-of-type(2) .address {
            display: none;
        }
        .row:nth-of-type(3) > div {
            margin: 0;
        }
    }
    @media screen and (max-width: 800px) {
        .contacts {
            display: none;
        }
        .row:nth-of-type(1) .logo {
            width: 150px;
            height: 100%;
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 375px) {

    }
</style>