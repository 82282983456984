<template>
    <div class="contacts">
        <div class="icon">
            <a href="tel:+79205352806"><img src="@/assets/phone.svg"></a>
        </div>
        <div class="info">
            <div class="call-number"><a href="tel:+79205352806">+7 920 535-28-06</a></div>
            <div>Без выходных с 9:00 до 21:00</div>
        </div>
        <div class="messenger__contact">
            <a href="https://wa.me/+79205352806" target="_blank"><img class="mess-icon" src="@/assets/whatsapp.svg"></a>
                <div class="mess-text__container">
                    <div class="call-number"><a href="https://wa.me/+79205352806" target="_blank"><strong>Написать в WhatsApp</strong></a></div>
                    <div class="mess-text">Без выходных в любое время</div>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contacts"
    }
</script>

<style scoped>
    .messenger__contact {
        display: flex;
        align-items: center;
        margin-left: 30px;
    }
    .mess-icon {
        width: 50px;
        object-fit: cover;
        margin-right: 5px;
    }
    .mess-text__container {
        text-align: left;
    }
    .mess-text {
        color: grey;
        font-size: 11px;
        margin-top: 3px;
        font-weight: normal;
    }
    .contacts {
        display: flex;
        align-items: center;
    }
    .icon {
        float: left;
        margin-right: 5px;
    }
    .icon > a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .icon img {
        width: 45px;
        margin-right: 5px;
    }
    .info {
        text-align: left;
        white-space: nowrap;
        display: inline-block;
        font-weight: bold;
    }
    .info > div:nth-of-type(2) {
        color: grey;
        font-size: 11px;
        margin-top: 3px;
        font-weight: normal;
    }
    .call-number > a {
        text-decoration: none;
        color: black;
    }
    .footer-content .info > div {
        color: #ffffff;
    }
    .footer-content .call-number> a {
        color: #ffffff;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    @media screen and (max-width: 690px) {
        div.info {
            font-size: 13px;
        }
        .call-number {
            font-size: 13px;
        }
        .mess-text {
            font-size: 9px;
        }
        div.info > div:nth-of-type(3) {
            font-size: 9px;
        }
        div.icon img {
            width: 38px;
        }
    }
    @media screen and (max-width: 520px) {

    }
    @media screen and (max-width: 450px) {
        .messenger__contact {
            display: none;
        }
        .footer-content .info > div {
            display: none;
        }
        .footer-content .icon img {
            display: none;
        }
    }
</style>