<template>
    <ul class="menu">
        <li class="dropdown">
            <div class="dropbtn">Заборы</div>
            <div class="dropdown-content">
                <div><router-link class="dropcnt" to="/proflist">Профлист</router-link></div>
                <div><router-link class="dropcnt" to="/shtaketnik">Евроштакетник</router-link></div>
                <div><router-link class="dropcnt" to="/rabica">Сетка рабица</router-link></div>
                <div><router-link class="dropcnt" to="/3D">3д сетка</router-link></div>
            </div>
        </li>
        <li class="dropdown">
            <div class="dropbtn">Ворота</div>
            <div class="dropdown-content">
                <div><router-link class="dropcnt" to="/raspashnye">Распашные</router-link></div>
                <div><router-link class="dropcnt" to="/otkatnye">Откатные</router-link></div>
            </div>
        </li>
        <li><div class="dropbtn"><router-link to="/kalitka">Калитки</router-link></div></li>
        <li><div class="dropbtn"><router-link to="/kozyrki">Козырьки</router-link></div></li>
        <li><div class="dropbtn"><router-link to="/navesy">Навесы</router-link></div></li>
        <li><div class="dropbtn"><router-link to="/besedky">Беседки</router-link></div></li>
        <li><div class="dropbtn"><router-link to="/kontakty">Контакты</router-link></div></li>

        <li class="menu-button">
            <burger-button></burger-button>
        </li>
    </ul>
</template>

<script>
    import BurgerButton from "./BurgerButton";
    export default {
        name: "Menu",
        components: {BurgerButton},
        methods:{
            goToPage(page) {
                this.$router.push(page);
                window.scrollTo(0,0);
            },
            toggleForm(){
                let form = document.getElementById("feedback-modal");
                form.style.display = (form.style.display == 'none') ? 'block' : 'none'
            }
        }
    };
</script>

<style scoped>
    a.dropcnt {
        text-decoration: none;
        color: #212121;
    }
    a.router-link-exact-active {
        color: #e21c21;
    }
    a {
        text-decoration: none;
        color: #212121;
    }
    a:hover {
        color: #e21c21;
    }
    .menu li {
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        padding: 15px 15px;
    }
    .menu li:hover {
        cursor: pointer;
        color: rgb(226, 28, 33);
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
    }

    li {
        float: left;
    }

    li div, .dropbtn {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        color: inherit;
    }

    li.dropdown {
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        transform: translateX(-30%);
        margin-top: 15px;
    }

    .dropdown-content div {
        color: black;
        padding: 15px 20px;
        text-decoration: none;
        display: block;
        text-align: center;
    }

    .dropdown-content div:hover .dropcnt {
        background-color: #f1f1f1;
        color: #e21c21;
    }
    .dropdown-content div:hover {
        background-color: #f1f1f1;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
    .dropbtn::selection {
        background: transparent;
    }
    .dropcnt::selection {
        background: transparent;
    }
    .menu-button {
        display: none;
        padding: 8px 25px 0px 0px !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    @media screen and (max-width: 800px) {
        li:not(.menu-button) {
        display: none;
        }
        li.menu-button {
        display: block;
        }
    }
</style>