import { render, staticRenderFns } from "./SliderKALITKI.vue?vue&type=template&id=0e401f9a"
import script from "./SliderKALITKI.vue?vue&type=script&lang=js"
export * from "./SliderKALITKI.vue?vue&type=script&lang=js"
import style0 from "../../node_modules/slick-carousel/slick/slick.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../node_modules/slick-carousel/slick/slick-theme.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./SliderKALITKI.vue?vue&type=style&index=2&id=0e401f9a&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports